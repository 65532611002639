import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class GlobalService {

    subject = new BehaviorSubject(false);

    public title = "";
    public CompanyName: string = "";
    public CompanyID: number = 0;
    public LocationID: number = 0;
    public MultiLocation: boolean = false;
    public ComponentID: number = 0;
    public UserID: number = 0;
    public EmployeeID: number = 0;
    public EmployeeName: string = "";
    public ClicktocallUserID: string = "";
    public ClicktocallUserPass: string = "";
    public LoginTemplate: string = "";
    public UserName: string = "";
    public LoginTemplateName: string = "";
    public MenuComponentAuthentication = [];
    public VisibleComponentMenu = {};
    public VisibleComponentMenuID = {};
    public OutOfTatUser: boolean = false;
    public EmployeeImagePath = "";
    public UserBucketList = "";
    public UserBucketListArr = [];
    public IsAppData: boolean = false;
    public today;
    public DateForComparing;
    public CurrentDateTime;
    public CurrentLocalDateTime;
    public TimeDifference;
    public CurrentDateTimeWithoutChanges;
    public SelectedPreviousDateTime;
    public PreviousDateTransaction = false;
    public FromDate: string = "";
    public ToDate: string = "";
    public LastVisitedComponent: string = "";
    public GSM: string = "";
    public Gender: string = "";
    public DOB: string = "";
    public GSM1: string = "";
    public PostalCode: string = "";
    public City: string = "";
    public Address: string = "";
    public IsPasswordCorrect: boolean = false;
    public ExpireTime: Date;
    public IsClientSuperAdmin: boolean = false; // Is Client Super Admin
    public IsSuperUser: boolean = false;
    public ISAdditionaBillApproval: boolean = false;
    messages: any = {};
    configurations: any = {};

    configurationsDetails = [];
    mstconfigurations: any = {};
    CaseDetailsID: number = 0;
    InvestigationID: number = 0;
    PolicyNumber: string = "";
    VerticalName: string = "";
    InsuredState: string = "";
    ClaimName: string = "";
    ClientID: number = 0;
    BranchID: number = 0;
    ClaimNo: string = "";
    BillID: number = 0;
    ProcessingPlatformVisible: boolean = false;
    VirtualInvestigationVisible: boolean = false;
    BulkCaseUploadVisible: boolean = false;
    RulingAuditVisible: boolean = false;
    MediaAuditVisible: boolean = false;
    MedicalAuditVisible: boolean = false;
    DesktopAuditVisible: boolean = false;
    AllocationVisible: boolean = false;
    NetworkingVisible: boolean = false;
    ClientBillVisible: boolean = false;
    VideoConferenceVisible: boolean = false;
    InvoiceExcelUploadVisible: boolean = false;
    InternalChatVisible: boolean = false;
    HistoricalDataVisible: boolean = false;
    FindingsVisible: boolean = false;
    HardCopyVisible: boolean = false;
    BillReconciliationVisible: boolean = false;
    ClosureVisible: boolean = false;
    VigilanceVisible: boolean = false;
    FoPaymentVisible: boolean = false;
    FoBillingVisible: boolean = false;
    FoFindingsVisible: boolean = false;
    commonReportVisible: boolean = false;
    userReportVisible: boolean = false;
    auditReportVisible: boolean = false;
    accountReportVisible: boolean = false;
    venderBillingVisible: boolean = false;
    public FOBill: number = 0;
    public FOPayment: number = 0;
    public ClientBill: number = 0;
    public ClientPayment: number = 0;
    public VendorBill: number = 0;
    public VendorPayment: number = 0;

    LatLongList = [];
    NewVisitLatLong = "";
    // public ClientID: number = 0;
    // public LocationID: number = 0;
    // public ClientAddress: string = "";
    // public HasClientInfo: boolean = false;
    // public MultiClientLocation: boolean = false;

    //  public RegNo: string = "";

    //  public IsDocterzClient: boolean = false; // Is Docterz Client
    //   public registrationVisible = false; // OPD / IPD Registration

    //   public ShowClientLocationPopUp: boolean = false;
    // public casePaperVisible: boolean = false; // OPD / IPD Case Paper
    // public billVisible: boolean = false;
    // public recordConsentVisible: boolean = false;
    // public ipdBillVisible: boolean = false; // IPD Billing
    // public ipdShortBillVisible: boolean = false; // IPD Short Billing
    // public ipdBillReconciliationVisible: boolean = false; // IPD Billing Reconciliation
    // public bedTransferVisible: boolean = false; // Bed Transfer
    // public ipdBillPaymentVisible: boolean = false; // IPD Bill Payment
    // public cashlessVisible: boolean = false; // Cashless
    // public cashlessDummyBillVisible: boolean = false; //CashlessDummyBill
    // public dischargeVisible: boolean = false; // Discharge
    // public IpdadvanceVisible: boolean = false; // OPD / IPD Advance
    // public OpdadvanceVisible: boolean = false; // OPD / IPD Advance
    // public IpdrefundVisible: boolean = false; // OPD / IPD Refund
    // public OpdrefundVisible: boolean = false; // OPD / IPD Refund
    // public nursingObservationVisible: boolean = false; // OPD / IPD Nursing Observation
    // public radiologyResultEntryVisible: boolean = false; // Radiology Result Entry
    // public pathologyResultEntryVisible: boolean = false; // Pathology Result Entry
    // public opdBillReconciliationVisible: boolean = false; // OPD Billing Reconciliation
    // public opdInvoicePaymentVisible: boolean = false; // OPD Invoice Payment
    // public doctorRequestVisible: boolean = false; // Doctor Request
    // public preAuthVisible:boolean = false; // Pre Auth
    // public emailVisible:boolean = false ; //email
    // public SessionDatavisible: boolean = false; // Laser Sheet
    // public pathologyReportVisible: boolean = false; // Pathology Report
    // public opdReportVisible: boolean = false; // opd Report
    // public ipdReportVisible: boolean = false; // ipd Report
    // public commonReportVisible: boolean = false; // common Report
    // public inventoryReportVisible: boolean = false; // opd Report
    // public IsNewPatientRegistartionbtn: boolean = false; // Ipd New Patient Registartion button



    // public BillID: number = 0;
    // public BillType: string = "";
    // public OpIpID: number = 0;
    // public OpIpFlag: boolean = true;
    // public IsPrint: boolean = false;

    // public Class: string = "";
    // public ReceiptBook: string = "";
    // public SponsorType: string = "";
    // public SelectedName: string = "";

    // public PatientID: number = 0;
    // public DocterzPatientID: number = 0;

    // public PatientName: string = "";
    // public PrimaryParentName: string = "";
    // public DocterzUHID: string = "";
    // public ThirdPartyUHID: string = "";

    // public SecondaryParentName: string = "";
    // public SecondaryParentGSM: string = "";

    // public DefaultStore: number = 0;
    // public DefaultSubStore: number = 0;
    // public LicenseFor: string = "";
    // public patientPrescriptonVisible = false; // Patient Prescription
    // public imageuploadVisible: boolean = false;   // Image upload

    // public LPOID: number = 0; // For LPO Selection

    // public LoginPatientID: number = 0;
    // public LoginPatientName: string = "";

    // // OPD
    // public OPDCash: number = 0;
    // public OPDCashSettlement: number = 0;
    // public OPDInvoice: number = 0;
    // public OPDInvoiceSettlement: number = 0;
    // public OPDAdvance: number = 0;
    // public OPDRefundBill: number = 0;
    // public OPDRefundAdvance: number = 0;

    // // IPD
    // public IPDCash: number = 0;
    // public IPDCashSettlement: number = 0;
    // public IPDInvoice: number = 0;
    // public IPDInvoiceSettlement: number = 0;
    // public IPDAdvance: number = 0;
    // public IPDRefundBill: number = 0;
    // public IPDRefundAdvance: number = 0;
    // public IPDShortBill:number = 0;

    // // Sales
    // public SalesID: number = 0;
    // public SalesCash: number = 0;
    // public SalesInvoice: number = 0;
    // public SalesBillSettlement: number = 0;
    // public SalesReturn: number = 0;
    // public salesRetunVisible: boolean = false;

    // // Goods Receipt Note

    // public GRNID: number = 0;
    // public GoodsReceiptNote: number = 0;
    // public GoodsReceiptNoteReturn: number = 0;
    // public goodsReceiptNoteRetunVisible: boolean = false;

    // // Indent
    // public Indent: number = 0;
    // public AllowNewIndentWOoldIndent: number = 0;

    // public externalPathologyBillingVisible: boolean = false; // External Pathology Billing
    // public PathSampleRequestID: number = 0; // Pathology Sample ID
    // public HospitalBelongsTo: string = "";
    // public SampleType: string = "";
    // public PathRequestFrom: string = "";
    // public PathRequestBy: string = "";

    // // Material Issue
    // public IssueID: number = 0;
    // public MaterialIssue: number = 0;
    // public ReturnFromSubStore: number = 0;
    // public returnFromSubStoreReturnVisible: boolean = false;

    // // Material Consume
    // public MaterialConsume: number = 0;


    constructor(private toastrService: ToastrService) { }

    // Setting Default Values For Global Service
    SetDefaultValuesForGlobal() {
        this.title = "";
        this.CompanyName = "";
        this.ComponentID = 0;
        this.CompanyID = 0;
        this.LocationID = 0;
        this.IsAppData = false;
        this.UserID = 0;
        this.EmployeeID = 0;
        this.EmployeeName = "";
        this.ClicktocallUserID = "";
        this.ClicktocallUserPass = "";
        this.LoginTemplate = "";
        this.UserName = "";
        this.LoginTemplateName = "";
        this.MenuComponentAuthentication = [];
        this.VisibleComponentMenu = {};
        this.VisibleComponentMenuID = {};
        this.today = "";
        this.DateForComparing = "";
        this.CurrentDateTime = "";
        this.CurrentDateTimeWithoutChanges = "";
        this.SelectedPreviousDateTime = "";
        this.PreviousDateTransaction = false;
        this.MultiLocation = false;
        this.FromDate = "";
        this.ToDate = "";
        this.LastVisitedComponent = "";
        this.GSM = "";
        this.Gender = "";
        this.DOB = "";
        this.GSM1 = "";
        this.PostalCode = "";
        this.City = "";
        this.Address = "";
        this.IsPasswordCorrect = false;
        this.ExpireTime = new Date();
        this.IsClientSuperAdmin = false; // Is Client Super Admin
        this.IsSuperUser = false;
        this.ISAdditionaBillApproval = false;
        this.messages = {};
        this.configurations = {};
        this.configurationsDetails = [];
        this.CaseDetailsID = 0;
        this.InvestigationID = 0;
        this.PolicyNumber = "";
        this.VerticalName = "";
        this.InsuredState = "";
        this.ClaimName = "";
        this.ClientID = 0;


        this.BranchID = 0;
        this.ClaimNo = "";
        this.ProcessingPlatformVisible = false;
        this.VirtualInvestigationVisible = false;
        this.BulkCaseUploadVisible = false;
        this.RulingAuditVisible = false;
        this.MediaAuditVisible = false;
        this.MedicalAuditVisible = false;
        this.DesktopAuditVisible = false;
        this.AllocationVisible = false;
        this.NetworkingVisible = false;
        this.ClientBillVisible = false;
        this.VideoConferenceVisible = false;
        this.InvoiceExcelUploadVisible = false;
        this.InternalChatVisible = false;
        this.HistoricalDataVisible = false;
        this.FindingsVisible = false;
        this.BillReconciliationVisible = false;
        this.HardCopyVisible = false;
        this.ClosureVisible = false;
        this.VigilanceVisible = false;
        this.FoPaymentVisible = false;
        this.FoBillingVisible = false;
        this.FoFindingsVisible = false;
        this.commonReportVisible = false;
        this.userReportVisible = false;
        this.auditReportVisible = false;
        this.accountReportVisible = false;
        this.venderBillingVisible = false;
        this.FOBill = 0;
        this.FOPayment = 0;
        this.ClientBill = 0;
        this.ClientPayment = 0;
        this.VendorBill = 0;
        this.VendorPayment = 0;
        this.OutOfTatUser = false;
        this.EmployeeImagePath = "";
        this.UserBucketList = "";
        this.UserBucketListArr = [];
        this.LatLongList = [];
        this.NewVisitLatLong = "";
        //   this.ClientID = 0;
        //   this.LocationID = 0;
        //  this.ClientAddress = "";
        // this.HasClientInfo = false;
        //   this.MultiClientLocation = false;

        // this.RegNo = '';

        //   this.IsDocterzClient = false; // Is Docterz Client
        //   this.registrationVisible = false; // OPD / IPD Registration

        //   this.ShowClientLocationPopUp = false;
        //  this.casePaperVisible = false; // OPD / IPD Case Paper
        //   this.billVisible = false;
        //   this.recordConsentVisible = false;
        //   this.ipdBillVisible = false; // IPD Billing
        // this.ipdShortBillVisible = false; // IPD Short Billing
        // this.ipdBillReconciliationVisible = false; // IPD Billing Reconciliation
        // this.ipdBillPaymentVisible = false; // IPD Bill Payment
        // this.bedTransferVisible = false; // Bed Transfer
        // this.cashlessVisible = false; // Cashless
        // this.cashlessDummyBillVisible = false; // Cashless Dummy Bill
        // this.dischargeVisible = false; // Discharge
        // this.IpdadvanceVisible = false; // OPD / IPD Advance
        // this.OpdadvanceVisible = false; // OPD / IPD Advance
        // this.IpdrefundVisible = false; // OPD / IPD Refund
        // this.OpdrefundVisible = false; // OPD / IPD Refund
        // this.nursingObservationVisible = false; // OPD /IPD Nursing Observation
        // this.radiologyResultEntryVisible = false; // Radiology Result Entry
        // this.pathologyResultEntryVisible = false; // Pathology Result Entry
        // this.opdBillReconciliationVisible = false; // OPD Bill Reconciliation
        // this.opdInvoicePaymentVisible = false; // OPD Invoice Payment
        // this.doctorRequestVisible = false; // Doctor Request
        // this.preAuthVisible = false;  // Pre Auth
        // this.emailVisible = false;  // email
        // this.SessionDatavisible = false; //  Session data for session services like laser sheet

        // this.BillID = 0;
        // this.BillType = "";
        // this.OpIpID = 0;
        // this.OpIpFlag = true;
        // this.IsPrint = false;

        // this.Class = "";
        // this.ReceiptBook = "";
        // this.SponsorType = "";
        // this.SelectedName = "";

        // this.PatientID = 0;
        // this.DocterzPatientID = 0;

        // this.PatientName = "";
        // this.PrimaryParentName = "";
        // this.DocterzUHID = "";
        // this.ThirdPartyUHID = "";

        // this.SecondaryParentName = "";
        // this.SecondaryParentGSM = "";

        // this.DefaultStore = 0;
        // this.DefaultSubStore = 0;
        // this.LicenseFor = "";

        // this.patientPrescriptonVisible = false; // Patient Prescription
        // this.imageuploadVisible = false;   // Image upload
        // this.LPOID = 0; // For LPO Selection

        // // For Login Patient
        // this.LoginPatientID = 0;
        // this.LoginPatientName = "";

        // // OPD
        // this.OPDCash = 0;
        // this.OPDCashSettlement = 0;
        // this.OPDInvoice = 0;
        // this.OPDInvoiceSettlement = 0;
        // this.OPDAdvance = 0;
        // this.OPDRefundBill = 0;
        // this.OPDRefundAdvance = 0;

        // // IPD
        // this.IPDCash = 0;
        // this.IPDCashSettlement = 0;
        // this.IPDInvoice = 0;
        // this.IPDInvoiceSettlement = 0;
        // this.IPDAdvance = 0;
        // this.IPDRefundBill = 0;
        // this.IPDRefundAdvance = 0;
        // this.IPDShortBill = 0;

        // // Sales
        // this.SalesID = 0;
        // this.SalesCash = 0;
        // this.SalesInvoice = 0;
        // this.SalesBillSettlement = 0;
        // this.salesRetunVisible = false;

        // // Goods Receipt Note
        // this.GRNID = 0;
        // this.GoodsReceiptNote = 0;
        // this.GoodsReceiptNoteReturn = 0;
        // this.goodsReceiptNoteRetunVisible = false;

        // // Indent
        // this.Indent = 0;
        // this.AllowNewIndentWOoldIndent = 0;

        // this.externalPathologyBillingVisible = false; // External Pathology Billing
        // this.PathSampleRequestID = 0; // Pathology Sample ID
        // this.HospitalBelongsTo = "";
        // this.SampleType = ""; // Sample Type
        // this.PathRequestFrom = "";
        // this.PathRequestBy = "";

        // this.pathologyReportVisible = false; // Pathology Report
        // this.opdReportVisible = false; // opd report
        // this.ipdReportVisible = false; // ipd report
        // this.commonReportVisible = false; // common report
        // this.inventoryReportVisible = false; // Inventory report
        // this.IsNewPatientRegistartionbtn = false; // Ipd New Patient Registartion button


        // // IssueToSubStore
        // this.IssueID = 0;
        // this.MaterialIssue = 0;
        // this.ReturnFromSubStore = 0;
        // this.returnFromSubStoreReturnVisible = false;

        // // Material Consume
        // this.MaterialConsume = 0;

    }

    // Getting Custom Headers For Get
    GetCustomHeadersForGet() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Post
    GetCustomHeadersForPost() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Delete
    GetCustomHeadersForDelete() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'DELETE'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Post form data
    GetCustomHeadersForPostFormData() {
        const customHeaders = {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST'
        };
        return customHeaders;
    }

    // Setting new boolean value to subject
    setSubjectBooleanValue(param: boolean) {
        this.subject.next(param);
    }

    // Showing messages using toastr and type of message
    ShowToastrMessage(type: string, message: string) {
        if (type === 'success') {
            this.toastrService.success(this.messages["msg_Success"]);
        } else if (type === 'warning') {
            this.toastrService.warning(message == "delete" ? this.messages["msg_Delete"] : message);
        } else if (type === 'error') {
            this.toastrService.error(this.messages["msg_Exception"]);
        }
    }
}
